/* eslint-disable lines-between-class-members */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { computed, action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { addObserver, removeObserver } from '@ember/object/observers';
import { postCustomInteraction } from 'mewe/utils/post-utils';
import PostApi from 'mewe/api/post-api';
import DiscoverApi from 'mewe/api/discover-api';
import PS from 'mewe/utils/pubsub';
import { Theme } from 'mewe/constants';
import PostUtils, { getPostTheme } from 'mewe/utils/post-utils';
import GroupStore from 'mewe/stores/group-store';
import { findGifUrl } from 'mewe/utils/gif-utils';
import { openPostbox } from 'mewe/utils/dialogs-common';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { FeedTypes } from 'mewe/constants';
import dispatcher from 'mewe/dispatcher';
import Mentions from 'mewe/utils/mentions-utils';
import HashtagsUtils from 'mewe/utils/hashtags-utils';
import storage from 'mewe/shared/storage';

export default class MwPost extends Component {
  @service router;
  @service settings;
  @service purchase;
  @service account;
  @service features;
  @service dynamicDialogs;
  @service dynamicPopups;

  @reads('args.feedTheme') feedTheme;
  @reads('args.scope') scope;
  @reads('args.post') post;
  @reads('args.post.isTranslated') isTranslated;

  @tracked postDropdownOpened;
  @tracked mentionsStrategy;
  @tracked hashtagsStrategy;
  @tracked didOpenComments;
  @tracked truncate;

  postApi = PostApi;
  profilePopupSelector = '.post_text .h-mention';
  initialPostText = this.post.textDisplay;

  constructor() {
    super(...arguments);

    if (this.args.isPostDialog && this.post.poll) {
      // if is single post dialog refresh options to show current status
      dispatcher.dispatch('poll', 'refreshVotes', this.post);
    }

    // public posts are displayed in full length and without "Show more" button
    if (this.args.post.isPublicViewPost) {
      this.setTruncate(false);
    }

    this.showProfilePopupBind = this.showProfilePopup.bind(this);
  }

  get videoFeedAutoPlaySetting() {
    return storage.get(storage.keys.videoFeedAutoPlaySetting);
  }

  @action
  onInsert() {
    if (this.post.placeholder) return;

    // in case of group posts we need to check if user is member of that group
    // so after fetching groups list we check if group was returned from BE
    if (this.post.groupId) {
      GroupStore.getState().deferred.promise.then(() => {
        if (this.isDestroyed || this.isDestroying) return;

        const fullGroupModel = GroupStore.getGroupIfMember(this.post.groupId);
        // if user is group member then we have full group object and
        // post can be updated with that to replace SimpleGroup model
        if (fullGroupModel) {
          // groups from GET/groups response don't have 'isMember' field
          // and it's important for have that info for handling author/origin link handling
          set(fullGroupModel, 'isMember', true);

          set(this, 'post.group', fullGroupModel);
        }
      });
    }

    addObserver(this, 'post.isEditing', this.editingObserver);

    this.isInserted = true;

    // TEST PERFORMANCE
    // if (window.testRunning && window.testRunning()) {
    //   if (this.post.firstPostPerformance) {
    //     next(this, () => {
    //       endMeasure('first-load');
    //     });
    //   }
    // }
    // TEST PERFORMANCE END
  }

  @action
  onDestroy() {
    if (this.isInserted) {
      removeObserver(this, 'post.isEditing', this.editingObserver);
    }
  }

  get isVerboseEnabled() {
    return localStorage.getItem('meweVerbose');
  }

  get autoPlayVideo() {
    if (!this.videoFeedAutoPlaySetting) {
      return false;
    }

    if (this.args.autoplayVideo == undefined && this.videoFeedAutoPlaySetting) {
      const { privacymail, pending, scheduled, textParts } = this.post;
      return !privacymail && !pending && !scheduled && !textParts;
    }

    return this.args.autoplayVideo;
  }

  @computed('purchase.premium.purchased')
  get hasPremium() {
    return this.purchase.get('premium.purchased'); // getter needed, otherwise throws error in Scheduled Posts list
  }

  @computed('args.page', 'post.{postedByPage,page}')
  get postPage() {
    if (this.args.page) return this.args.page;
    else if (this.post.postedByPage && this.post.page) return this.post.page;
  }

  @computed('post.{discoverScope,isGroupPreview}')
  get isGroupPostPreview() {
    return this.post.discoverScope === 'group' || this.post.isGroupPreview;
  }

  @computed('post.isPublicContent', 'isGroupPostPreview')
  get isDisabledPostInteraction() {
    return this.post?.isPublicContent || this.isGroupPostPreview;
  }

  // this is for completely disabling link and not haveing any interaction
  @computed('post.{groupId,group.isMember}')
  get isAuthorLinkDisabled() {
    // for refPosts in public view disable author link completely
    if (this.post.isPublicContent && this.args.isRefPost) return true;

    // disable group/profile link for group posts if user is not member of group
    if (this.post.groupId && !this.post.group.isMember) return true;
  }

  // for public Page and Profile we show custom interaction popup,
  // but for public post we allow click on author link to open profile
  // (except for ref posts)
  @computed('post.{isPublicContent,isPublicViewPost,isRefPost}')
  get hasAuthorLinkCustomInteraction() {
    return this.post.isPublicContent && !this.post.isPublicViewPost && !this.args.isRefPost;
  }

  showProfilePopup(target) {
    if (this.post?.isPublicContent) return;
    if (!this.isDestroyed && !this.isDestroying) {
      this.dynamicPopups.openPopup('mw-profile-popup', {
        parent: target,
        owner: { id: target.getAttribute('data-userid') },
        group: this.post.group,
        isMention: true,
      });
    }
  }

  // placeholderObserver: observer('post.placeholder', function () {
  //   if (!this.isInserted) return;

  //   if (this.get('post.placeholder')) return;

  //   // TEST PERFORMANCE
  //   if (window.testRunning && window.testRunning()) {
  //     if (this.get('post.firstPostPerformance')) {
  //       next(this, () => {
  //         endMeasure('first-load');
  //       });
  //     }
  //   }
  //   // TEST PERFORMANCE END
  // }),

  editingObserver() {
    if (!this.hashtagsStrategy) {
      const post = this.post;
      const mentionsScope = Mentions.getPostMentionsScope(post);
      const { scope, scopeId } = PostUtils.getPostScopeAndId(post);
      this.hashtagsStrategy = HashtagsUtils.getHashtagStrategy(scope, scopeId);
      this.mentionsStrategy = Mentions.createTextCompleteStrategy(mentionsScope, post);
    }
  }

  @computed('feedTheme', 'args.{isSmartSearch,isPostDialog,isSharedPost}', 'post.{threadId,discoverScope,postedByPage}')
  get showOriginName() {
    if (this.post.threadId) return false;
    if (this.post.postedByPage) return false;
    if (this.args.isSharedPost) return true;

    return (
      this.feedTheme === Theme.GROUPS ||
      (this.feedTheme === FeedTypes.ALL && this.post.groupId) ||
      this.post.discoverScope ||
      this.args.isSmartSearch ||
      this.args.isPostDialog ||
      (this.post.scheduled && this.post.groupId)
    );
  }

  @computed('post.textDisplay', 'post.album')
  get showPostText() {
    return this.post.textDisplay || this.post.album;
  }

  @computed('post.{photo,video,medias.length,eventInfo}', 'args.inMediaDialog')
  get showPostPhoto() {
    if (this.post.eventInfo || this.args.inMediaDialog) return false;
    if (this.post.photo && !(this.post.video && this.post.medias?.length == 1)) return true;

    return this.post.medias?.length > 1; // multiple photos, videos combined in one post
  }

  @computed('post.textServer')
  get isGifLinkWithGifInText() {
    return (
      !!findGifUrl(this.post.link?._links?.url?.href) &&
      this.post.textServer.indexOf(this.post.link?._links?.url?.href) !== -1
    );
  }

  @computed('args.inMediaDialog')
  get showPostVideo() {
    return !this.args.inMediaDialog && this.post.medias?.length == 1 && this.post.medias?.[0]?.video;
  }

  @computed('post.album', 'args.inMediaDialog', 'args.threadId')
  get showPostAlbumName() {
    return this.post.album && !this.args.inMediaDialog && !this.args.threadId;
  }

  @computed('args.isSmartSearch', 'post.isGroupPreview')
  get showOpenCommentsButton() {
    return (
      this.args.isSmartSearch ||
      (this.post.isGroupPreview && this.post.comments?.total) ||
      (this.post?.isPublicContent && this.post.comments?.total)
    );
  }

  @computed('args.commentsOpened', 'didOpenComments')
  get commentsOpened() {
    return this.didOpenComments ?? this.args.commentsOpened;
  }

  @computed('args.{postContext,scope,isFromMyWorld}')
  get postContext() {
    const { postContext, scope, isFromMyWorld } = this.args;

    if (postContext) {
      if (postContext === 'alerts') {
        return scope + '_alerts';
      }
      return postContext;
    }

    const feedContexts = {
      all: 'feed_all',
      contacts: 'feed_following',
      discover: 'feed_discover',
      groups: 'feed_groups',
      pages: 'feed_pages',
    };

    if (isFromMyWorld && feedContexts[scope]) {
      return feedContexts[scope];
    }

    const directContexts = ['groups', 'group', 'pages', 'page', 'event', 'profile'];
    if (directContexts.includes(scope)) {
      return scope;
    }

    // Default case if no conditions are met
    return undefined;
  }

  @computed(
    'post.{canFeature,canRemove,canAddToAlbum,canReport,canEdit,cannotFollow,canReschedule}',
    'args.hidePostbar'
  )
  get showDropdown() {
    const conditions = {
      canFeature: this.post.canFeature,
      canRemove: this.post.canRemove,
      canAddtoAlbum: this.post.canAddToAlbum,
      canReport: this.post.canReport,
      canEdit: this.post.canEdit,
      cannotFollow: !this.post.cannotFollow,
      canReschedule: this.post.canReschedule,
    };

    const testValues = Object.values(conditions).includes(true);

    return testValues && !this.args.hidePostbar;
  }

  @computed('post.group.{alreadyApplied,isMember}', 'args.postInPostbox', 'args.isRefPost')
  get showPendingApplication() {
    //checking for !isMember in case user joined group since application (SG-34474)
    return (
      this.post.group.alreadyApplied && !this.post.group.isMember && !this.args.postInPostbox && !this.args.isRefPost
    );
  }

  @computed('args.isSharedPost', 'feedTheme', 'post.{scheduled,groupId}')
  get showSharingInfo() {
    const allFeedScope = this.feedTheme === FeedTypes.ALL && !this.post.isGroupPost && !this.post.page;
    const isScheduledToContacts = this.post.scheduled && !this.post.groupId && this.feedTheme !== 'page';
    const isProperScope =
      this.feedTheme === 'contacts' || this.feedTheme === 'profile' || allFeedScope || isScheduledToContacts;

    if (!isProperScope) return false;
    if (this.args.isSharedPost) return false;

    return true;
  }

  isHashtagClicked(target) {
    const hashtagElementClicked =
      target.classList.contains('h-hashtag') ||
      (target.classList.contains('word-highlight') && target.parentNode.classList.contains('h-hashtag'));

    // when clicked on ht in shared post then click is handled twice,
    // click handled by outer post needs to be prevented
    const clickInsideSharedPost = this.post.refPost && target.closest('.shared-post');

    return hashtagElementClicked && !clickInsideSharedPost;
  }

  isVideoClicked(target) {
    return target.closest('.post_content--video');
  }

  isLinkClicked(target) {
    return target.closest('a');
  }

  isTranslationClicked(target) {
    return target.closest('.c-mw-translation');
  }

  isCustomInteractionAllowed(target) {
    // allow redirecting to post owner's profile in public view
    return target.closest('.post_header_user-wrapper');
  }

  @action
  clickHandler(e) {
    // post to share/repost, preview in postbox during sharing
    if (this.args.postInPostbox) return;

    const target = e.toElement || e.relatedTarget || e.target;

    if (this.post?.customInteraction && !this.isCustomInteractionAllowed(target)) {
      e.preventDefault();
      postCustomInteraction(this.dynamicDialogs, this.post, target, { context: this.postContext });
      return;
    }

    if (this.args.skin == 'skin-shared') {
      if (this.post?.customInteraction) return false;
      if (this.isLinkClicked(target)) return false; // could be the post author/origin or a link in post content
      if (this.isVideoClicked(target)) return false; // video player, don't open SPD when clicked on video
      if (this.isTranslationClicked(target)) return false; //prevent from opening popup when clicked on translation
      if (this.isHashtagClicked(target)) return false;

      let s = PostUtils.getPostScopeAndId(this.post);
      let postScope = s.scope;
      let postScopeId = s.scopeId;

      let opt = {
        scope: postScope,
        scopeId: postScopeId,
        postItemId: this.post.postItemId,
        fetchingPostForDialog: true,
        doWithPost: (thePost) => {
          this.dynamicDialogs.openDialog('single-post-dialog', {
            post: thePost,
            theme: postScope,
            scope: postScope,
            isRefPost: true,
            allowMultipleInstances: true,
          });
        },
      };

      if (this.post.page) opt.pageId = this.post.page.id;
      if (this.post.groupId) opt.groupId = this.post.groupId;

      dispatcher.dispatch('feed', 'fetchPostFromMemoryOrServer', opt);

      return false;
    }

    if (this.isHashtagClicked(target)) {
      if (this.post.isEditing) return false;
      if (this.post.isScheduled) return false;
      if (this.post.discoverScope) return false;
      if (this.post.isGroupPreview) return false;
      // hashtags are not supported in MC feed
      if (this.scope === Theme.MYCLOUD) return;
      // opened in new tab
      if (e.ctrlKey) return;

      if (this.args.isRefPost && this.post.groupId) {
        const confirmedGroup = GroupStore.getGroupIfMember(this.post.group.id);

        if (!confirmedGroup) {
          window.location.pathname = 'group/' + this.post.groupId;
          return false;
        }
      }

      const htEl = target.parentNode.classList.contains('h-hashtag') ? target.parentNode : target;
      // hashtags are not supported in comments
      if (htEl.closest('.comment_text')) return;

      if (htEl.closest('.chat-info-media') && this.post.group.id) {
        PS.Pub('chat.minimize', this.post.group.id);
      }

      const innerHTML = htEl.innerHTML.indexOf('word-highlight') > -1 ? htEl.innerText : htEl.innerHTML;
      const tag = innerHTML.trim().replace('#', '').toLowerCase();

      document.documentElement.scrollTop = 0; // scroll to top of feed when opening hashtag feed

      // openHashTagFromTop - callback passed from top (route or some other parent) to the post,
      // in order to keep the context of feed when opening hashtag feed. If it's missing then go to fallback
      if (this.args.openHashTagFromTop) {
        this.args.openHashTagFromTop(tag, this.post.pageId || this.post.eventId || this.post.group.id, this.post);
        e.preventDefault();
      }
      // openHashTagFromPost - callback passed from post down to child components in order to open
      // openHashTagFromTop callback of post that opened the media dialog
      // (use case: open photo-post with hasthag in /groups feed, then click on hashtag in MD -> /groups?tag= should be opened)
      else if (this.args.openHashTagFromPost) {
        this.args.openHashTagFromPost(e, tag);
      } else {
        this.openHashTagFallback(tag);
      }

      this.dynamicDialogs.closeAll();
    }
  }

  @action
  openHashTagFromPost(e, tag) {
    if (this.args.openHashTagFromTop) {
      this.clickHandler(e);
    } else {
      this.openHashTagFallback(tag);
    }
  }

  openHashTagFallback(tag) {
    let href,
      groupId,
      params = A();

    if (this.feedTheme === Theme.PROFILE && this.scope === Theme.PROFILE) {
      href = 'app.publicid.posts';
      params.push(this.post.owner.publicLinkId);
    } else if (this.post.eventId) {
      href = 'app.event.feed';
      params.push(this.post.eventId);
    } else if (this.post.pageId) {
      href = 'app.publicid.feed';
      params.push(this.post.page?.publicLinkId);
    } else if (this.post.group) {
      groupId = this.post.group.id;

      if (groupId !== Theme.PRIVATEPOSTS) {
        if (groupId === Theme.CONTACTS) {
          href = 'app.myworld.index';
        } else {
          href = 'app.group.index.feed';
          params.push(groupId);
        }
      }
    }

    if (href) {
      let query = {
        tag: tag.replace('#', '').toLowerCase(),
      };

      // set scope to All posts when redirecting to myworld feed to get global hashtags
      if (href === 'app.myworld.index') {
        query.scope = 'all';
      }

      if (params.length) this.router.transitionTo(href, ...params, { queryParams: query });
      else this.router.transitionTo(href, { queryParams: query });
    }
  }

  @computed('post.pending', 'args.hidePostbar')
  get postbarVisible() {
    return this.args.postbarVisible ?? (!this.post.pending && !this.args.hidePostbar);
  }

  @computed('post.postedByPage', 'post.page.isOwnerAdmin', 'args.isSharedPost')
  get showPublisher() {
    return this.post.postedByPage && this.post.page?.isOwnerAdmin && !this.args.isSharedPost;
  }

  @computed('args.hidePostbar')
  get textHeight() {
    return this.args.hidePostbar && !this.args.isSharedPost ? 92 : 180;
  }

  @computed('post.groupId', 'args.page')
  get queryScope() {
    if (this.post.groupId) {
      return `/group/${this.post.groupId}/scheduled/post`;
    } else if (this.args.page?.id) {
      return `/pages/page/${this.args.page.id}/scheduled/post`;
    } else {
      return `/home/scheduled/post`;
    }
  }

  @computed('post.textDisplay', 'args.{isRefPost,isPostDialog}')
  get displayCommentsDisabledText() {
    if (this.args.isPostDialog) {
      return true;
    } else if (!this.args.isRefPost) {
      return true;
    }
    return false;
  }

  get displayTranslationFeature() {
    const isLangDifferent = this.account.currentTranslationLanguage !== this.post.language;
    const canTranslate =
      this.post.language &&
      isLangDifferent &&
      this.features.get('translationsPosts') &&
      this.post.textDisplay &&
      this.hasMinimumRequiredChars &&
      !this.post.isCurrentUserPostOwner;

    if (this.post.poll) {
      return (
        this.post.isTranslated ||
        (this.post.language &&
          isLangDifferent &&
          this.features.get('translationsPolls') &&
          this.hasMinimumRequiredChars &&
          !this.post.isCurrentUserPostOwner)
      );
    }

    return this.post.isTranslated || canTranslate;
  }

  get hasMinimumRequiredChars() {
    let pollQuestionLength = 0;
    let pollOptionsLength = 0;
    const textLength = this.post.textServer?.length;

    if (this.post.poll) {
      pollQuestionLength = this.post.poll.question?.length || 0;
      this.post.poll.options?.forEach((option) => (pollOptionsLength += option.text?.length));
    }

    return textLength + pollQuestionLength + pollOptionsLength > this.account.minimumNumberOfCharacters;
  }

  @action
  handleTranslations(translation) {
    if (translation && !translation.errorCode) {
      set(this.args.post, 'isTranslated', translation.isTranslated);
      set(this.args.post, 'textServer', translation.data.text);

      if (this.post.poll) {
        if (translation.isTranslated) {
          this.post.poll.options?.forEach((option, index) => {
            set(option, 'textTranslated', translation.data.options[index]?.textTranslated);
          });
        }

        set(this, 'post.poll.question', translation.data.question);
      }
    }
  }

  @action
  openAlbumMediaDialog() {
    if (this.post?.customInteraction) {
      postCustomInteraction(this.dynamicDialogs, this.post);
      return;
    }

    const post = this.post;
    // shared post should be opened in SPV and there user can open album
    if (this.args.isSharedPost) return;

    let scope = post.scope || this.scope || this.feedTheme;

    if (scope == FeedTypes.ALL) {
      scope = PostUtils.getPostScopeAndId(post).scope;
    }

    this.dynamicDialogs.openDialog('media-dialog', {
      galleryView: true,
      mediaType: 'album',
      post: post,
      groupId: post.group.id,
      eventId: post.eventId || post.eventInfo?.id,
      userId: post.owner?.id, // owner undefined for e.g. page posts
      pageId: post.pageId,
      album: post.album,
      scope: scope,
      isMyCloud: this.feedTheme === Theme.MYCLOUD,
      isCurrentUserPostOwner: post.isCurrentUserPostOwner,
      allowMultipleInstances: true,
    });
  }

  @action
  togglePostDropdown() {
    if (this.post?.isPublicContent) return;
    this.postDropdownOpened = !this.postDropdownOpened;
  }

  @action
  closePostDropdown() {
    this.postDropdownOpened = false;
    this.args.onCloseUpdate?.(this.post);
  }

  @action
  closeSmartSearch() {
    this.closeSmartSearch();
  }

  @action
  shareTo(target) {
    const ppOrigin = target === 'privacymail' ? 'share-post' : null;

    this.hideTranslations();

    openPostbox(
      {
        target: target,
        postToShare: this.post.refPost ? this.post.refPost : this.post,
        theme: getPostTheme(this.post, this.feedTheme),
        ppOrigin: ppOrigin,
      },
      this.dynamicDialogs
    );
  }

  @action
  repostTo(target) {
    const ppOrigin = target === 'privacymail' ? 'repost-post' : null;

    this.hideTranslations();

    openPostbox(
      {
        target: target,
        postToRepost: this.post,
        theme: getPostTheme(this.post, this.feedTheme),
        ppOrigin: ppOrigin,
      },
      this.dynamicDialogs
    );
  }

  hideTranslations() {
    if (this.isTranslated) {
      set(this.args.post, 'textDisplay', this.initialPostText);
      set(this.args.post, 'isTranslated', false);
    }
  }

  @action
  showAppliedDialog() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      closeBtnText: __(`Got it!`),
      message: __(
        `You have applied to join this group. As soon as the group owner approves, you will be able to visit the group and engage with the posts and members.`
      ),
    });
  }

  @action
  unhidePost() {
    if (this.post.temporarilyHidden === 'singlePost') {
      DiscoverApi.unignoreContent('post', this.post.id).then(() => {
        set(this, 'post.temporarilyHidden', false);
      });
    } else {
      const ignoreType = this.post.postedByPage ? 'page' : 'group';
      const ignoreId = this.post.postedByPage ? this.post.pageId : this.post.groupId;

      DiscoverApi.unignoreContent(ignoreType, ignoreId).then(() => {
        const posts = this.args.collection.posts.filter((p) => {
          if (ignoreType === 'group') {
            return p.groupId === ignoreId;
          } else {
            return p.pageId === ignoreId;
          }
        });
        posts.forEach((p) => set(p, 'temporarilyHidden', false));
      });
    }
  }

  @action
  hideSchedule() {
    set(this, 'post.isEditingSchedule', false);
  }

  @action
  schedule(timestamp) {
    const post = this.post;

    if (this.isSharing) return;

    PostApi.reschedule({
      params: {
        schedule: timestamp,
      },
      postId: post.id,
      scope: this.queryScope,
    })
      .then((data) => {
        post.setProperties({
          isEditingSchedule: false,
        });

        this.updateDate(timestamp);

        FunctionalUtils.info(__('Post Rescheduled!'));
      })
      .catch((error) => {
        if (error.status === 400 && error.data?.message)
          FunctionalUtils.error(__(`Sorry, this post can't be rescheduled. An error occured`));
      });
  }

  @action
  updateDate(newTimestamp) {
    set(this, 'post.scheduled', newTimestamp);
  }

  @action
  toggleComments() {
    if (this.post.isGroupPreview) return;
    this.didOpenComments = !this.didOpenComments;
  }

  @action
  setFeatured(doFeature) {
    set(this, 'post.featured', doFeature);
  }

  @action
  setTruncate(value) {
    this.truncate = value;
  }
}
